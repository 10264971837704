import {
  LightUserProductType,
  PreferenceApi as PreferenceApiClient,
  Preferences,
  ProductTypesApi as ProductTypesApiClient,
  UserProductType,
  UserProductTypeOperationCode,
} from 'pleinchamp-api-client';
import { ApiConfiguration, axiosInstance } from '@api/business/api.conf';
import { createModel, ModelConfig, RematchDispatch } from '@rematch/core';
import { getUserProductTypeMarketId } from '@stocks/business/Stocks.utils';
import { PlcEffects, PlcReducers, RootState } from '@store/store';
import { updatePushNotification } from './settings.utils';

const ProductTypesApi = new ProductTypesApiClient(ApiConfiguration, undefined, axiosInstance);
const PreferenceApi = new PreferenceApiClient(ApiConfiguration, undefined, axiosInstance);

export interface SettingsState {
  isFreemiumDisabled: boolean;
  preferences: Preferences;
  isPreferencesLoaded: boolean;
  pushNotificationDenied: boolean;
  pushNotificationError?: string;
  errorCode?: number;
  productTypes: UserProductType[];
}

export const defaultPreferences: Preferences = {
  activities: [],
  emailNotificationEnabled: false,
  forecasts: [],
  interests: [],
  isPrefsSet: false,
  newsletterEnabled: false,
  partnersOffersEnabled: false,
  plcOffersEnabled: false,
  pushNotificationEnabled: false,
};

export const defaultState: SettingsState = {
  isFreemiumDisabled: false,
  isPreferencesLoaded: false,
  preferences: defaultPreferences,
  productTypes: [],
  pushNotificationDenied: false,
};

export const settingsSelectors = {
  isPreferencesLoaded: (rootState: RootState) => rootState.settings.isPreferencesLoaded,
};

const reducers = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  clearSettings(_: SettingsState): SettingsState {
    return defaultState;
  },
  setError(state: SettingsState, errorCode: number): SettingsState {
    return {
      ...state,
      errorCode,
    };
  },
  setIsFreemiumDisabled(state: SettingsState) {
    return { ...state, isFreemiumDisabled: true };
  },
  setPreferences(state: SettingsState, preferences: Preferences): SettingsState {
    return {
      ...state,
      errorCode: undefined,
      isPreferencesLoaded: true,
      preferences,
    };
  },
  setProductTypes(state: SettingsState, productTypes: UserProductType[]): SettingsState {
    return {
      ...state,
      errorCode: undefined,
      productTypes,
    };
  },
  setPushNotificationDenied(state: SettingsState, pushNotificationDenied: boolean): SettingsState {
    return {
      ...state,
      pushNotificationDenied,
    };
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setPushNotificationError(
    state: SettingsState,
    pushNotificationError: SettingsState['pushNotificationError']
  ): SettingsState {
    return {
      ...state,
      pushNotificationError,
    };
  },
};

interface SettingsEffectsReturnType {
  fetchPreferences: (payload?: never, rootState?: RootState) => Promise<void>;
  setPreferences: (preferences: Preferences, rootState: RootState) => Promise<void>;
  putPreferences: (preferences: Partial<Preferences>, rootState?: RootState) => Promise<void>;
  addProductType: (payload: LightUserProductType, rootState?: RootState) => Promise<void>;
  removeProductType: (payload: LightUserProductType, rootState?: RootState) => Promise<void>;
  fetchUserProductTypes: (payload?: never, rootState?: RootState) => Promise<void>;
  removeUserProductTypes: (payload: LightUserProductType[], rootState?: RootState) => Promise<void>;
  savePendingPreferences: (_?: never, rootState?: RootState) => Promise<void>;
}

// Do not put PlcDispatch to avoid a circular dependency
const effects = (dispatch: any): SettingsEffectsReturnType => ({
  async addProductType(payload, rootState) {
    if (!rootState || !rootState.auth.isAuthenticated) {
      return;
    }
    try {
      // TODO: revert when api will return added productType
      await ProductTypesApi.insertUserProductType(payload);
      await dispatch.settings.fetchUserProductTypes();
      // const { data: productType } = await UserApi.insertUserProductType({ id });
      // await dispatch.settings.setProductTypes([...productTypes, productType]);
    } catch (e) {
      if (e.response) {
        dispatch.settings.setError(e.response.status);
      }
    }
  },
  async fetchPreferences(_, rootState) {
    if (!rootState || !rootState.auth.isAuthenticated) {
      return;
    }

    try {
      const preferences = await PreferenceApi.getPreferences().then(response => response.data);
      const nextState = {
        ...rootState.settings.preferences,
        ...preferences,
      };
      dispatch.settings.setPreferences(nextState);
    } catch (e) {
      if (e.response) {
        dispatch.settings.setError(e.response.status);
      }
    }
  },
  async fetchUserProductTypes(_, rootState) {
    if (!rootState || !rootState.auth.isAuthenticated) {
      return;
    }
    try {
      const { data: productTypes } = await ProductTypesApi.getUserProductTypes();

      dispatch.settings.setProductTypes(productTypes);
    } catch (e) {
      if (e.response) {
        dispatch.settings.setError(e.response.status);
      }
    }
  },
  async putPreferences(preferences, rootState) {
    if (rootState) {
      const newPrefs: Preferences = { ...rootState.settings.preferences, ...preferences, isPrefsSet: true };
      try {
        await PreferenceApi.putPreferences(newPrefs);
        dispatch.settings.setPreferences({ ...newPrefs });
      } catch (e) {
        if (e.response) {
          dispatch.settings.setError(e.response.status);
        }
      }
    }
  },
  async removeProductType(payload, rootState) {
    if (!rootState || !rootState.auth.isAuthenticated) {
      return;
    }
    const {
      settings: { productTypes },
    } = rootState;
    const { id, marketId } = payload;
    try {
      await ProductTypesApi.patchUserProductTypes({
        items: [{ id, marketId }],
        operation: UserProductTypeOperationCode.Delete,
      });
      const newProductTypes = productTypes.filter(pt => pt.id !== id && getUserProductTypeMarketId(pt) === marketId);
      dispatch.settings.setProductTypes(newProductTypes);
    } catch (e) {
      if (e.response) {
        dispatch.settings.setError(e.response.status);
      }
    }
  },
  async removeUserProductTypes(payload, rootState) {
    if (!rootState || !rootState.auth.isAuthenticated) {
      return;
    }
    const {
      settings: { productTypes },
    } = rootState;
    try {
      await ProductTypesApi.patchUserProductTypes({
        items: payload.map(lpt => ({ id: lpt.id, marketId: lpt.marketId })),
        operation: UserProductTypeOperationCode.Delete,
      });
      const newProductTypes = productTypes.filter(
        pt => !payload.some(lpt => lpt.id === pt.id && lpt.marketId === getUserProductTypeMarketId(pt))
      );
      dispatch.settings.setProductTypes(newProductTypes);
    } catch (e) {
      if (e.response) {
        dispatch.settings.setError(e.response.status);
      }
    }
  },
  async savePendingPreferences(_, rootState) {
    if (!rootState) {
      return;
    }
    const {
      pendingSettings: { preferences: pendingPrefs, productTypes },
    } = rootState;

    const saveSettings = async () => {
      if (pendingPrefs.activities.length === 0 && pendingPrefs.interests.length === 0) {
        return;
      }
      // Update preferences to be up to date before saving pending ones
      const preferences = await PreferenceApi.getPreferences().then(response => response.data);

      if (pendingPrefs.activities.length > 0) {
        preferences.activities = pendingPrefs.activities;
      }
      if (pendingPrefs.interests.length > 0) {
        preferences.interests = pendingPrefs.interests;
      }
      await dispatch.settings.putPreferences(preferences);
    };
    const saveProductTypes = productTypes.map(async pt =>
      dispatch.settings.addProductType({
        id: pt.id,
        marketId: getUserProductTypeMarketId(pt),
      })
    );
    await Promise.all([saveSettings(), ...saveProductTypes]);
  },
  async setPreferences(_, rootState) {
    updatePushNotification(rootState, dispatch);
  },
});

export type SettingsReducersType = PlcReducers<SettingsState, typeof reducers>;
export type SettingsEffectsType = PlcEffects<typeof effects>;

function createSettingsModel(withEffects: boolean) {
  return createModel<SettingsState>({
    effects: withEffects ? (effects as RematchDispatch) : undefined,
    reducers,
    state: defaultState,
  });
}

const settings: ModelConfig<SettingsState> = createSettingsModel(true);
const pendingSettings: ModelConfig<SettingsState> = createSettingsModel(false);

export { settings, pendingSettings };
